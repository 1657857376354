import { render, staticRenderFns } from "./OrderForms.vue?vue&type=template&id=09a5f1ea&"
import script from "./OrderForms.vue?vue&type=script&lang=js&"
export * from "./OrderForms.vue?vue&type=script&lang=js&"
import style0 from "./OrderForms.vue?vue&type=style&index=0&id=09a5f1ea&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports