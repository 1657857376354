<template>
  <rpm-layout>
    <div class="flex justify-between items-center bg-white rounded-lg p-2 mb-2">
      <div class="relative w-1/3">
        <RpmSearch v-model="search"></RpmSearch>
      </div>
      <div>
        <el-select
            v-model="filterStatus"
            placeholder="Status"
            class="v-select"
        >
          <el-option
              v-for="item in ['', 'crm', 'pending', 'created']"
              :key="item"
              :label="item ? item : 'All Statuses'"
              :value="item"
              class="capitalize"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <el-select
            v-model="filterClient"
            placeholder="Client"
            class="v-select"
        >
          <el-option
              :key="'all'"
              :label="'All Clients'"
              :value="'all'"
          >
          </el-option>
          <el-option
              v-for="client in uniqueClients"
              :key="client.client_id"
              :label="client.client_name"
              :value="client.client_id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <button type="button" @click="refresh"
                class="py-2.5 px-5 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 ">
          Refresh
        </button>
      </div>
    </div>
    <v-data-table
        :headers="dtheaders" :loading="loading" :items="dtorderforms" :search="search" @click:row="clickOrderForm" sort-by="created_at" sort-desc
        :items-per-page="10" :page.sync="page" @page-count="pageCount = $event" hide-default-footer class="v-data-table cursor-pointer">
      <template v-slot:loading><v-progress-circular indeterminate></v-progress-circular></template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :class="orderStatusColors[item.status]" text-color="white" class="capitalize">
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:[`item.dob`]="{ item }">
        {{ item.dob | dob }}
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | datetime }}
      </template>
      <template v-slot:footer>
        <v-pagination v-model="page" :length="pageCount" :total-visible="7" class="py-4"/>
      </template>
    </v-data-table>

    <!-- Step Panel Modals -->
    <el-dialog :close-on-click-modal="false" :title="panelModalTitle" :visible.sync="dialogVisible" width="50%" class="josefin_font"
               @close="closeDialogModal()">
      <div class="dialog__body">
        <div ref="panelRegister" v-show="panelRegister">
          <RpmDashboardErrors v-bind:errors="registerErrors" v-bind:title="'Registration Validation Errors'">
          </RpmDashboardErrors>
          <Patient :patient="newPatient" :statuses="statuses" :auth-user="this.patients.authUser" :payers="this.patients.payers" />
        </div>
      </div>
      <div v-if="panelAgreement" class="panel__agreement" ref="panelAgreement">
        <RpmDashboardErrors v-bind:errors="agreementErrors" v-bind:title="'Agreement Errors'"></RpmDashboardErrors>
        <Agreement :patient="newPatient" :legal-form="this.patients.legalForm" :auth-user="this.patients.authUser" :legal-check="legalCheck" />
      </div>
      <div ref="panelRpmCarePlan" v-if="panelRpmCarePlan" class="panel__rpm_careplan">
        <RpmDashboardErrors v-bind:errors="rpmErrors" v-bind:title="'RPM Validation Errors'"></RpmDashboardErrors>

        <div class="dialog__body">
          <RpmCarePlan v-model="carePlan" :templates="rpmCarePlanTemplates"  :patient="newPatient" />
        </div>
      </div>

      <div ref="panelCcmCarePlan" v-if="panelCcmCarePlan" class="panel__ccm_careplan">
        <RpmDashboardErrors v-bind:errors="ccmErrors" v-bind:title="'CCM Validation Errors'"></RpmDashboardErrors>

        <div class="dialog__body">
          <CcmCarePlan v-model="carePlan" :templates="ccmCarePlanTemplates" :patient="newPatient" />
        </div>
      </div>

      <div ref="panelOrderForm" v-if="panelOrderForm" class="panel__order_form">
        <RpmDashboardErrors v-bind:errors="orderFormErrors" v-bind:title="'Order Form Errors'"></RpmDashboardErrors>
        <order-form :patient="createdPatient" :provider_id_prop="orderFormProviderId" :selected_devices_prop="orderFormDevices" @hide-order-form="closeDialogModal" />
      </div>

      <span v-if="panelRegister" slot="footer" class="josefin dialog-footer">
        <el-button @click="createNewPatient" type="primary" :disabled="loading">Register patient</el-button>
      </span>
      <span v-if="panelRpmCarePlan" slot="footer" class="josefin dialog-footer">
        <el-button @click="saveRpmCarePlan" type="primary" :disabled="loading">Save RPM Care Plan</el-button>
        <el-button @click="stepPanelCcmCarePlan" type="secondary">Skip to CCM Care Plan</el-button>
      </span>
      <span v-if="panelCcmCarePlan" slot="footer" class="josefin dialog-footer">
        <el-button @click="saveCcmCarePlan" type="primary" :disabled="loading">Save CCM Care Plan</el-button>
        <el-button @click="cancelCcmCarePlan" type="cancel">Cancel</el-button>
      </span>
      <span v-if="panelAgreement" slot="footer" class="josefin dialog-footer">
        <div class="flex justify-between items-center">
          <div class="flex">
            <el-button @click="closeDialogModal" class="btn__decline">DECLINE</el-button>
            <el-button @click="agreeToLegal" :disabled="!this.legalChecked || loading" type="primary">Accept</el-button>
          </div>
        </div>
      </span>
    </el-dialog>


  </rpm-layout>
</template>


<script>
import {mapActions, mapState} from "vuex";
import RpmLayout from '../../layouts/RpmLayout.vue';
import RpmSearch from "@/components/RpmSearch.vue";
import CcmCarePlan from "@/components/CcmCarePlan.vue";
import Agreement from "@/components/Agreement.vue";
import Patient from "@/components/Patient.vue";
import RpmCarePlan from "@/components/RpmCarePlan.vue";
import RpmDashboardErrors from "@/components/RpmDashboardErrors.vue";
import Vue from "vue";
import Template from "@/mixins/Template";
import PatientMixin from "@/mixins/Patient.js";
import OrderForm from "@/components/OrderForm.vue";
import statuses from '@/helpers/patientStatuses.js';

export default {
  components: {
    OrderForm,
    Template, RpmDashboardErrors, RpmCarePlan, Patient, Agreement, CcmCarePlan, RpmLayout, RpmSearch},
  mixins: [PatientMixin],
  data() {
    return {
      statuses: statuses,
      filterStatus: 'pending',
      filterClient: 'all',
      loading: true,
      accessToken: null,
      page: 1,
      pageCount: 0,
      search: "",
      dialogVisible: false,
      panelRegister: true,
      currentPanel: "register",
      panelAgreement: false,
      panelRpmCarePlan: false,
      panelCcmCarePlan: false,
      panelOrderForm: false,
      panelModalTitle: 'New Patient Onboarding (CCM/RPM) — 1/5',
      registerErrors: [],
      rpmErrors: [],
      ccmErrors: [],
      agreementErrors: [],
      orderFormErrors: [],
      currentOrderForm: {},
      carePlan: [],
      orderFormProviderId: null,
      orderFormDevices: [],
      newPatient: {
        status: 'onboard',
        primary_client_id: null,
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        dob: "",
        sex: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country_id: "1",
        member_id: "",
        member_group_id: "",
        payer_id: "",
        care_plans: [],
        emergency_contact: {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          cell: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country_id: "1",
        }
      },
      createdPatient: {},
      legalCheck: {
        firstContact: false,
        cellService: false,
        dailyCommitment: false,
        reviewTos: false,
      },
    };
  },
  created: async function () {
    await this.getAccessToken();
    Promise.all([
      !this.$store.state.patients.authUser.id ? this.$store.dispatch('getAuthUser') : null,
      !this.$store.state.patients.providers.length ? this.$store.dispatch('getProviders') : null,
      !this.$store.state.patients.orderForms.length ? this.$store.dispatch('getOrderForms') : null,
      !this.$store.state.patients.templates.length ? this.$store.dispatch('getTemplates') : null,
      !this.$store.state.patients.payers.length ? this.$store.dispatch('getPayers') : null,
    ]).finally(() => this.loading = false);
  },
  computed: {
    ...mapState(["patients"]),
    orderStatusColors() {
      return {
        pending: 'blue',
        crm: 'orange',
        created: 'green',
        '': 'red',
      }
    },
    uniqueClients() {
      const clients = this.patients.orderForms.map(order => ({
        client_id: order.client_id,
        client_name: order.client_name
      }));
      const uniqueClients = Array.from(new Set(clients.map(client => client.client_id)))
          .map(id => clients.find(client => client.client_id === id));
      return uniqueClients.sort((a, b) => a.client_id - b.client_id);
    },
    dtheaders() {
      return [
        {text: 'ID', value: 'id'},
        {text: 'Client', value: 'client_name'},
        {text: 'Name', value: 'name'},
        {text: 'DOB', value: 'dob'},
        {text: 'Email', value: 'email'},
        {text: 'Status', value: 'status'},
        {text: 'Created At', value: 'created_at'},
      ]
    },
    dtorderforms() {
      return this.ordersFiltered.map((orderForm) => ({
        ...orderForm,
        status: orderForm.status,
        name: `${orderForm.data.patient.first_name} ${orderForm.data.patient.last_name}`,
        dob: orderForm.data.patient.dob,
        email: orderForm.data.patient.email,
        client_id: orderForm.client_id,
        created_at: orderForm.created_at,
      }))
    },
    ordersFiltered() {
      let orderForms = this.patients.orderForms;
      if (this.filterStatus) {
        orderForms = orderForms.filter((o) => o.status === this.filterStatus);
      }
      if (this.filterClient && this.filterClient !== 'all') {
        orderForms = orderForms.filter((o) => o.client_id === this.filterClient);
      }
      if (this.search) {
        orderForms = orderForms.filter((o) => {
          return Object.values(o.data.patient).join(' ').toLowerCase().includes(this.search.toLowerCase());
        });
      }
      return orderForms;
    },
    templates: function () {
      return this.patients.templates;
    },
    rpmCarePlanTemplates: function () {
      return this.templates.filter(t => t.category === 'rpm_care_plan');
    },
    ccmCarePlanTemplates: function () {
      return this.templates.filter(t => t.category === 'ccm_care_plan');
    },
    legalChecked: function () {
      return this.legalCheck.firstContact && this.legalCheck.cellService && this.legalCheck.dailyCommitment && this.legalCheck.reviewTos;
    },
  },
  methods: {
    ...mapActions(['getOrderForms', 'getAuthUser', 'getTemplates']),
    refresh() {
      this.loading = true;
      this.$store.dispatch('getOrderForms').finally(() => this.loading = false);
    },
    async getAccessToken() {
      this.accessToken = await this.$auth.getTokenSilently();
    },
    clickOrderForm(item) {
      const orderForm = this.dtorderforms[this.dtorderforms.indexOf(item)];

      if (orderForm.patient_id) {
        this.goToPatient(orderForm.patient_id)
      }
      else {
        this.openDialogModal(orderForm);
      }
    },
    openDialogModal(orderForm) {
      this.currentOrderForm = orderForm;
      this.newPatient = Object.assign({}, this.newPatient, orderForm['data']['patient']);
      this.newPatient.primary_client_id = this.currentOrderForm.client_id;
      this.currentPanel = "register";

      this.openStepPanel();
      this.dialogVisible = true;
    },
    closeDialogModal() {
      this.dialogVisible = false;
      this.closeStepPanels();
    },
    saveRpmCarePlan() {
      if (!this.checkRpmForm()) {
        this.$nextTick(() => {
          this.$refs.panelRpmCarePlan.scrollIntoView();
        });
        return;
      }

      let filteredAlerts = [];
      for (const key in this.carePlan.alerts) {
        const alert = this.carePlan.alerts[key];
        if (alert.min && alert.max && alert.frequency) {
          filteredAlerts.push(alert);
        }
      }

      this.loading = true;

      const payload = {
        patient_id: this.createdPatient.id,
        alerts: JSON.stringify(filteredAlerts),
        type: 'rpm',
        notes: this.carePlan.notes,
        health_condition: this.carePlan.health_condition,
        start_date: this.carePlan.start_date,
        end_date: this.carePlan.end_date,
        icd_codes: this.carePlan.icd_codes,
        order_form_id: this.currentOrderForm.id,
      }

      Vue.$http.post(`/api/rpm_ccm_dashboard/care-plan`, payload, { params: { token: this.accessToken } }
      ).then((res) => {
        this.createdPatient.care_plans.push(res.data.data);
        this.stepPanelCcmCarePlan();
      })
          .catch((error) =>
              this.handleEhrApiErrors(
                  error,
                  this.rpmErrors,
                  this.$refs.panelRpmCarePlan
              )
          ).finally(() => {
        this.loading = false;
      });
    },
    saveCcmCarePlan() {
      if (!this.checkCcmForm()) {
        this.$nextTick(() => {
          this.$refs.panelCcmCarePlan.scrollIntoView();
        });
        return;
      }

      this.loading = true;

      const payload = {
        patient_id: this.createdPatient.id,
        type: 'ccm',
        notes: this.carePlan.notes,
        health_condition: this.carePlan.health_condition,
        start_date: this.carePlan.start_date,
        icd_codes: this.carePlan.icd_codes,
        order_form_id: this.currentOrderForm.id,
      };

      Vue.$http.post(`/api/rpm_ccm_dashboard/care-plan`, payload, { params: { token: this.accessToken } }
      ).then((res) => {
        this.createdPatient.care_plans.push(res.data.data);
        this.stepPanelOrderForm();
      })
          .catch((error) =>
              this.handleEhrApiErrors(
                  error,
                  this.ccmErrors,
                  this.$refs.panelCcmCarePlan
              )
          ).finally(() => {
        this.loading = false;
      });
    },
    cancelCcmCarePlan() {
      this.closeDialogModal();
      this.closeStepPanels();
      this.resetNewPatient();
    },
    createNewPatient() {
      if (!this.checkRegisterForm()) {
        this.$nextTick(() => {
          this.$refs.panelRegister.scrollIntoView();
        });

        return;
      }

      this.loading = true;

      const payload = {
        ...this.newPatient,
        order_form_id: this.currentOrderForm.id,
      };

      Vue.$http
          .post(`/api/rpm_ccm_dashboard/create_patient`, payload, {
            params: { token: this.accessToken },
          })
          .then((response) => {
            let data = response.data.data;

            this.createdPatient = data;
            this.createdPatient.care_plans = [];
            this.$store.dispatch('getAuthUser');
            this.$store.dispatch("getOrderForms");
            this.stepPanelAgreement();
          })
          .catch((error) =>
              this.handleEhrApiErrors(
                  error,
                  this.registerErrors,
                  this.$refs.panelRegister
              )
          ).finally(() => {
        this.loading = false;
      });
    },
    handleEhrApiErrors(errorResponse, vueErrors, ref) {
      let response = errorResponse.response.data;
      if (response.errors && response.errors.length > 0) {
        for (const key in response.errors) {
          if (response.errors.hasOwnProperty(key)) {
            for (const fieldKey in response.errors[key]) {
              if (response.errors[key].hasOwnProperty(fieldKey)) {
                vueErrors.push(key + ": " + response.errors[key][fieldKey]);
              }
            }
          }
        }
      } else {
        vueErrors.push(response.message);
      }
      this.$nextTick(() => {
        ref.scrollIntoView();
      });
    },
    agreeToLegal() {
      this.loading = true;

      Vue.$http
          .post(
              `/api/rpm_ccm_dashboard/agree_to_legal`,
              {},
              {
                params: {
                  patient_id: this.createdPatient.id,
                  token: this.accessToken
                },
              }
          )
          .then(() => {
            this.stepPanelRpmCarePlan();
          })
          .catch((error) =>
              this.handleEhrApiErrors(
                  error,
                  this.agreementErrors,
                  this.$refs.panelAgreement
              )
          ).finally(() => {
        this.loading = false;
      });
    },
    resetNewPatient() {
      this.currentPanel = "register";
      this.newPatient = {
        primary_client_id: null,
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        dob: "",
        sex: "",
        timezone: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country_id: "1",
        member_id: "",
        member_group_id: "",
        payer_id: "",
        emergency_contact: {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          cell: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country_id: "1",
        }
      };
      this.createdPatient = {};
      this.legalCheck = {
        firstContact: false,
        cellService: false,
        dailyCommitment: false,
        reviewTos: false,
      };
      this.resetCarePlan();
    },
    resetLegalCheck() {
      this.legalCheck = {
        firstContact: false,
        cellService: false,
        dailyCommitment: false,
        reviewTos: false,
      };
    },
    resetCarePlan() {
      this.rpmErrors = [];
      this.ccmErrors = [];

      this.carePlan = {};
      this.carePlan.icd_codes = [];
      this.carePlan.alerts = [
        { type: "weight", min: -5, max: 5, min_warning: -4, max_warning: 4, frequency: "daily" },
        { type: "pulse", min: 50, max: 100, min_warning: 55, max_warning: 95, frequency: "daily" },
        { type: "systolic", min: 95, max: 160, min_warning: 102, max_warning: 153, frequency: "daily" },
        { type: "diastolic", min: 50, max: 90, min_warning: 54, max_warning: 86, frequency: "daily" },
        { type: "oxy", min: 90, max: 100, min_warning: 91, max_warning: 99, frequency: "daily" },
        { type: "peak_expiratory_flow", min: null, max: null, min_warning: null, max_warning: null, frequency: null },
        { type: "blood_glucose_nonfasting", min: 65, max: 280, min_warning: 87, max_warning: 258, frequency: "daily" },
        { type: "temperature", min: 97, max: 100, min_warning: 98, max_warning: 99, frequency: "daily" }
      ];
    },
    checkRpmForm() {
      const required = ['HealthCondition', 'Notes'];
      this.rpmErrors = this.checkForm(required, { HealthCondition: this.carePlan.health_condition, Notes: this.carePlan.notes });

      for (const key in this.carePlan.alerts) {
        let alert = this.carePlan.alerts[key];

        if (alert.min || alert.max || alert.frequency) {
          if (!(alert.min && alert.max && alert.frequency)) {
            this.rpmErrors.push(
                "Alert " +
                key +
                " needs to have min, max and frequency filled out."
            );
          }

          const min = parseFloat(alert.min);
          const max = parseFloat(alert.max);
          const min_warning = parseFloat(alert.min_warning);
          const max_warning = parseFloat(alert.max_warning);

          if (isNaN(min) || isNaN(max)) {
            this.rpmErrors.push(
                "Alert " + alert.type + " min/max need to be valid numbers."
            );
          } else if (min >= max) {
            this.rpmErrors.push(
                "Alert " + alert.type + " needs to have a valid min/max range."
            );
          }

          if (isNaN(min_warning) || isNaN(max_warning)) {
            this.rpmErrors.push(
                "Alert " + alert.type + " min warning/max warning need to be valid numbers."
            );
          } else if (min_warning >= max_warning) {
            this.rpmErrors.push(
                "Alert " + alert.type + " needs to have a valid min_warning/max_warning range."
            );
          } else if (min_warning <= min || max_warning >= max) {
            this.rpmErrors.push(
                "Alert " + alert.type + " needs to have a valid min_warning/max_warning range."
            );
          }
        }
      }

      return !this.rpmErrors.length;
    },
    checkCcmForm() {
      const required = ['HealthCondition', 'Notes'];
      this.ccmErrors = this.checkForm(required, { HealthCondition: this.carePlan.health_condition, Notes: this.carePlan.notes });
      return !this.ccmErrors.length;
    },
    checkRegisterForm() {
      const required = ['primary_client_id', 'state', 'first_name', 'status'];
      this.registerErrors = this.checkForm(required, this.newPatient);
      return !this.registerErrors.length;
    },
    checkForm(required, formValuesObj) {
      let errors = [];

      for (const key in required) {
        if (!formValuesObj.hasOwnProperty(required[key])) {
          errors.push(required[key].replace("_", " ") + " is required.");
        } else if (
            formValuesObj.hasOwnProperty(required[key]) &&
            required.indexOf(required[key]) !== -1 &&
            !formValuesObj[required[key]]
        ) {
          errors.push(required[key].replace("_", " ") + " is required.");
        }
      }

      return errors;
    },
    stepPanelRegister() {
      this.currentPanel = "register";
      this.openStepPanel();
    },
    stepPanelAgreement() {
      this.currentPanel = "agreement";
      this.openStepPanel();
    },
    stepPanelRpmCarePlan() {
      // Build default care plan data
      this.resetCarePlan();

      this.orderFormProviderId = 'provider_id' in this.currentOrderForm['data'] ? this.currentOrderForm['data']['provider_id'] : null;
      this.orderFormDevices = 'selected_devices' in this.currentOrderForm['data'] ? this.currentOrderForm['data']['selected_devices'] : [];

      // Preserve alerts
      const alerts = this.carePlan.alerts;
      this.carePlan = 'rpm_care_plan' in this.currentOrderForm['data'] ? this.currentOrderForm['data']['rpm_care_plan'] : {};
      this.carePlan.alerts = alerts;
      this.currentPanel = "rpm";
      this.openStepPanel();
    },
    stepPanelCcmCarePlan() {
      // Build default care plan data with no alerts
      this.resetCarePlan();
      this.carePlan = 'ccm_care_plan' in this.currentOrderForm['data'] ? this.currentOrderForm['data']['ccm_care_plan'] : {};
      this.currentPanel = "ccm";
      this.openStepPanel();
    },
    stepPanelOrderForm() {
      this.currentPanel = "order-form";
      this.openStepPanel();
    },
    openStepPanel() {
      this.closeStepPanels();

      switch (this.currentPanel) {
        case "register":
          this.panelModalTitle = "New Patient Onboarding (CCM/RPM) — 1/5";
          this.panelRegister = true;
          break;
        case "agreement":
          this.panelModalTitle = "New Patient Onboarding (CCM/RPM) — 2/5";
          this.panelAgreement = true;
          break;
        case "rpm":
          this.panelModalTitle = "New Patient RPM Care Plan (CCM/RPM) — 3/5";
          this.panelRpmCarePlan = true;
          break;
        case "ccm":
          this.panelModalTitle = "New Patient CCM Care Plan (CCM/RPM) — 4/5";
          this.panelCcmCarePlan = true;
          break;
        case "order-form":
          this.panelModalTitle = "New Patient Order Form (CCM/RPM) — 5/5";
          this.panelOrderForm = true;
          break;
      }
    },
    closeStepPanels() {
      this.panelRegister = false;
      this.panelAgreement = false;
      this.panelRpmCarePlan = false;
      this.panelCcmCarePlan = false;
    },
  },
};
</script>


<style>
.green_button {
  padding: 0.25rem 0.75rem;
}
.v-data-table th {
  white-space: nowrap;
}
</style>
